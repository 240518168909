import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ITransaction} from '../model/cityIdcore/transaction.model';
import {createRequestOption} from '../util/util/request-util';
import {environment} from '../../../environments/environment';
import { Cancel } from '../model/cityIdcore/cancel.model';

type EntityResponseType = HttpResponse<ITransaction>;
type EntityArrayResponseType = HttpResponse<ITransaction[]>;

export class TransactionList {
  public transactions : ITransaction[];
  public returns : Object;
}
@Injectable({providedIn: 'root'})
export class TransactionService {
  public resourceUrl = environment.SERVER_API_URL + 'api/admin/transactions';

  constructor(protected http: HttpClient) {
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ITransaction>(`${this.resourceUrl}/${id}`, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req? : any) : Observable<HttpResponse<TransactionList>> {
    const options = createRequestOption(req);
    return this.http
      .get<TransactionList>(this.resourceUrl, {params: options, observe: 'response'});
  }

  cancelTransaction(reason:Cancel){
    return this.http.put(`${this.resourceUrl}-cancel`,reason);
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.time = res.body.time != null ? res.body.time : null;
    }
    return res;
  }


}
