import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VoucherService } from 'src/app/shared/service/voucher.service';

export class AddVoucherData {
  id: number;
  description: string;
  count: number;
}

@Component({
  selector: 'app-add-voucher-dialog',
  templateUrl: './add-voucher-dialog.component.html',
  styleUrls: ['./add-voucher-dialog.component.scss']
})
export class AddVoucherDialogComponent  implements OnInit {

  title: "Select voucher to be added";
  min: number =1;
  max:number = 100;
  step:number = 1;
  value = 1;
  programId: number;
  ids: number[] = [];
  availableVouchers:[];
  selectedVouchers:[];



  constructor(
    private dialogRef: MatDialogRef<AddVoucherDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private voucherService: VoucherService) { 
    this.programId = data.programId;  
    this.ids = data.voucherIds; 
  }

  ngOnInit(): void {
    this.voucherService.findVouchersByProgram(this.programId, "maximum eq 0").subscribe(resp => {
      this.availableVouchers = resp.body.data.filter(v => !this.ids.includes(v['@id'])).filter(v => v['restrictedTo'] === undefined);
    });
  }

  save() {
    var result:AddVoucherData[] = [];
    this.selectedVouchers.forEach(v => {
      result.push({id: v['@id'], description: v['description'], count: this.value});
    });
    this.dialogRef.close(result);
  }

  cancel() {
    this.dialogRef.close();
  }
}
