import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Cancel } from 'src/app/shared/model/cityIdcore/cancel.model';

@Component({
  selector: 'app-cancel-dialog',
  templateUrl: './cancel-dialog.component.html',
  styleUrls: ['./cancel-dialog.component.scss']
})
export class CancelDialogComponent  {

  title: "Cancel Transaction";
  id: string;
  cancelReason: string;
  cancelTransaction : Cancel;
  
  constructor(
    private dialogRef: MatDialogRef<CancelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {    
  }

  save() {
    let cancelTransaction = new Cancel();
    cancelTransaction.reason = this.cancelReason;
    cancelTransaction.id = Number(this.id);
    this.dialogRef.close(cancelTransaction);
  }

  cancel() {
    this.dialogRef.close();
  }
}
