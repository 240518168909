<h1 mat-dialog-title>{{ title }}</h1>

<mat-dialog-content>
  <div class="input-container">
    <div fxLayout="column" fxLayoutGap="20px" style="align-items: baseline">
      <div>
        <mat-form-field appearance="outline" class="fieldwidthLarge">
            <mat-label translate="cityIdApp.global.field.id"></mat-label>
            <input type="number" matInput placeholder="{{ 'cityIdApp.global.field.id' | translate }}" name="id"  [(ngModel)]="id"  required>
        
          </mat-form-field>

      </div>
      <mat-form-field appearance="outline" class="fieldwidthLarge">
        <mat-label translate="cityIdApp.cityIdcoreTransaction.cancelReason"></mat-label>
        <input matInput placeholder="{{ 'cityIdApp.cityIdcoreTransaction.cancelReason' | translate }}" name="cancelReason"  [(ngModel)]="cancelReason"  required>

      </mat-form-field>

      <div>

      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <mat-toolbar class="bottom-bar">
    <span class="rightalign-spacer"></span>
    <button
     [disabled]="id === null || id === undefined || id === '' || cancelReason === null || cancelReason === undefined || cancelReason === ''"
      mat-raised-button
      color="primary"
      (click)="save()"
      translate="cityIdApp.general.save"
    ></button>
    <button
      mat-raised-button
      color="warn"
      (click)="cancel()"
      translate="cityIdApp.general.cancel"
    ></button>
  </mat-toolbar>
</mat-dialog-actions>
